<template>
  <div>
    <ImageSearch @search="imageSearch" v-if="!visible"/>
    <a-row class="work-container" v-if="!visible && card.length!==0" style="margin-top: 20px;">
      <a-row class="cat-title">
        我的收藏
      </a-row>
    </a-row>

    <AppCard :card="card" @cardClick="cardClick" v-if="!visible" @cancelCollection="cancelCollection"/>

    <AppContentDrawer
        :card="card"
        v-else
        @closePanel="closePanel"
        :currentId.sync="curId"/>

    <div class="app-collect" style="margin-top: 45px;" v-if="card.length===0">
      <div class="not-have">
        <img src="null.png"/>
        <p>哎呦，您还没有收藏应用哦！</p>
      </div>
    </div>
    <!--    <a-row class="work-container" v-if="!visible">-->
    <!--      <template v-for="(item, index) in catArr">-->
    <!--        <a-row class="cat-title">-->
    <!--          {{ item.cat_name }}-->
    <!--        </a-row>-->
    <!--        <AppCard :card="item.apps" @cardClick="cardClick"/>-->
    <!--      </template>-->
    <!--    </a-row>-->
    <!--    <AppContentDrawer-->
    <!--        :card="card"-->
    <!--        v-else-->
    <!--        @closePanel="closePanel"-->
    <!--        :currentId.sync="curId"-->
    <!--    />-->
    <!--        <DefaultFooter></DefaultFooter>-->
  </div>

</template>

<script>

import DefaultFooter from '../../../components/Footers/DefaultFooter' ;
import {findCollection} from "@/api/center";

export default ({
  components: {
    DefaultFooter,
    AppCard: () => import('@/components/Application/AppCard.vue'),
    AppContentDrawer: () => import('@/views/application/component/AppContentDrawer.vue'),
    ImageSearch: () => import('@/views/application/drawer/component/ImageSearch.vue')
  },
  data() {
    return {
      // catArr: [],
      visible: false,
      curId: '',
      curCardDetail: {},
      card: []

    }
  },
  computed: {
    // card: {
    //   get() {
    //     let arr = []
    //     this.catArr.forEach(it => {
    //       arr = arr.concat(it.apps)
    //     })
    //     return arr
    //   }
    // }
  },
  methods: {
    imageSearch() {

    },
    closePanel() {
      this.visible = false
    },
    init() {
      findCollection().then(res => {
        if (res.success) {
          this.card = res.data
        }
      })
    },
    cardClick(cur) {
      this.visible = true
      this.curId = cur.id
      // const val = this.catArr.filter(item => item.id === cur.id)
      // if (val) {
      //   this.curCardDetail = val[0]
      // }
    },
    cancelCollection() {
      this.init()
    }
  },
  mounted() {
    this.init()
  }
})

</script>


<style lang="scss">
.work-container {
  padding-right: 15px;

  .cat-title {
    margin: 15px 20px;
    font-size: 18px;
    font-weight: 600;
  }
}

.nav-link svg {
  margin-right: 5px;
  vertical-align: middle;
}

.nav-link span {
  vertical-align: middle;
}

.ant-menu-submenu-popup {
  width: 100%;
}

</style>
